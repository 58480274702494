.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.app-header {
  height: 50px;
  align-items: center;
  background-color: #cc0000;
  position: fixed;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  top: 0;
  left: 0;
  z-index: 1302;
  color: #fff;
}
.back-button {
  font-weight: 400;
  font-size: 12px;
  margin: 16px 0px 0px;
  text-align: initial;
  font-family: "Roboto", sans-serif !important;
}

.app-footer {
  height: 68px;
  padding: 20px 0 0 0;
  background-color: #555555;
  position: fixed;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  bottom: 0;
  left: 0;
  z-index: 1302;
  color: white;
}
.button-connect .ant-btn:hover,
.button-connect .ant-btn:focus {
  width: 100%;
  margin-bottom: 8px;
  border-radius: 5px;
  height: 50px;
  border: none;
  background-color: #cc0000 !important;
  font-weight: 500 !important;
  color: #fff !important;
  font-size: 20px !important;
}
.reg_icon svg {
  font-size: 18px;
}
.not-verification {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-weight: 500;
  color: black;
  font-size: 14px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
